.question__panel {
  background-color: #041729;
  padding: 20px;
  height: 35vh;
  width: 800px;
  margin: 20px auto;
  border-radius: 8px;
}

.question__content {
  display: inline;
  height: 100%;
}
