.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 1rem;
  margin: 30px;
}

.card {
  background: #efefef;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  padding: 20px;
  text-align: left;
}

.card__correct {
  box-shadow: 0 10px 20px rgba(0, 255, 0, 1), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card__wrong {
  box-shadow: 0 10px 20px rgba(175, 0, 0, 1), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card__title {
  text-align: center;
  font-weight: 700;
}

.card__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.card__image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.center {
  text-align: center;
}

.vertical-container {
  // @TODO: normally the height would be 100vh here but we have a sticky footer
  // @TODO: probably need to figure out how to do this better
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
