.quiz-container {
  padding: 20px;
  text-align: center;
  background-color: #333333; /* Dark background */
  color: #ffffff; /* White text */
}

.input-group {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.input-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.input-form input {
  padding: 12px;
  font-size: 16px;
  width: 400px;
  border: none;
  background-color: #555555; /* Darker input background */
  color: #ffffff; /* White text */
  margin-right: 10px;
}

.country-quiz-button {
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  background-color: #555555; /* Darker button background */
  color: #ffffff; /* White text */
  cursor: pointer;
}

.country-quiz-button:hover {
  background-color: #777777; /* Darker hover color */
}

.countries-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.letter-section {
  padding: 20px;
  text-align: left;
  background-color: #555555; /* Darker section background */
  border-radius: 15px;
  margin: 10px;
}

.letter-section h2 {
  margin-bottom: 10px;
}

.letter-section ul {
  list-style-type: none;
  padding: 0;
}

.letter-section li {
  margin-bottom: 10px;
}

.guessed {
  color: #f5e31d; /* Bright yellow for guessed answers */
}

.not-guessed {
  color: red;
}

.not-revealed {
  color: #ffffff; /* White text for unrevealed countries */
}
