/* body {
  margin: 0;
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
  font-family: Poppins;
  width: 100vw;
  height: 100vh;
  background-color: #faf6cb;
  display: flex;
  justify-content: center;
  align-items: center;
} */

body {
  background-color: #faf6cb;
}

.brutal-card {
  width: 340px;
  box-sizing: border-box;
  box-shadow: 5px 5px #303030;
  /*   transform: rotate(-2deg); */
}

.brutal-card a {
  color: white;
}

.brutal-card .header {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  background: #ffffff;
  padding: 10px 20px;
  border: solid #303030 3px;
}

.brutal-card .content {
  font-size: 16px;
  color: #ffffff;
  background: #a379fa;
  padding: 20px;
  border-right: solid #303030 3px;
  border-bottom: solid #303030 3px;
  border-left: solid #303030 3px;
}
