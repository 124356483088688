.country-list {
  border: 1px solid black;
  margin: 10px;
  max-width: 400px;
}

.country {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 8px;
  background-color: white;
}
